import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { environment.apiUrl } from '../../../environment.apiUrls/environment.apiUrl';
import { environment } from '../../../environments/environment';
import { MasterDataDisplayColumns, MasterDataType } from '../../admin/admin';
import { SKIP_TOKEN } from '../../core/interceptors/auth.interceptors';



@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private loginServiceUrl = environment.apiUrl + 'login';
  private logoutServiceUrl = environment.apiUrl + 'logout';
  private logoutAllServiceUrl = environment.apiUrl + 'logout/all';
  private loginOtpServiceUrl = environment.apiUrl + 'login';
  private loginOtpGenerateServiceUrl = environment.apiUrl + 'login/otp/generate';
  private loginVerifyOtpServiceUrl = environment.apiUrl + 'login/otp/verify';
  private getUserMobileNumbersUrl = environment.apiUrl + 'getUserMobileNumbers';

  private listAllRolesUrl = environment.apiUrl + 'userRoles';
  private listAllUsersWithRolesUrl = environment.apiUrl + 'getUsersWithRole';
  private listAllUsersWithoutRolesUrl = environment.apiUrl + 'getUsersWithoutRole';
  private listUsersByRoleNameUrl = environment.apiUrl + 'getUsersByRole';
  private createRoleUrl = environment.apiUrl + 'userRoles';
  private getRoleByIdUrl = environment.apiUrl + 'userRoles';
  private updateRoleUrl = environment.apiUrl + 'userRoles';
  private deleteRoleUrl = environment.apiUrl + 'userRoles';
  private getRolesForUserUrl = environment.apiUrl + 'getRolesForUser';
  private assignRolesForUserUrl = environment.apiUrl + 'updateUserRole';
  private assignPermissionsForRoleUrl = environment.apiUrl + 'updatePermissionsForRole';
  private getAllPermissionsForRoleUrl = environment.apiUrl + 'getPermissionsForRole';


  private listAllPermissionsUrl = environment.apiUrl + 'userPermissions';
  private listAllUsersWithPermissionsUrl = environment.apiUrl + 'getUsersWithPermission';
  private listAllUsersWithoutPermissionsUrl = environment.apiUrl + 'getUsersWithoutPermission';
  private listUsersByPermissionNameUrl = environment.apiUrl + 'getUsersByPermission';
  private createPermissionUrl = environment.apiUrl + 'userPermissions';
  private getPermissionByIdUrl = environment.apiUrl + 'userPermissions';
  private updatePermissionUrl = environment.apiUrl + 'userPermissions';
  private deletePermissionUrl = environment.apiUrl + 'userPermissions';

  private getAllUsersAddedByUserIdUrl = environment.apiUrl + 'getAllUsersAddedByUserId';
  private getUserCreationCountPerDayUrl = environment.apiUrl + 'getUserCreationCountPerDay';
  //private getAllPermissionsForRoleUrl = environment.apiUrl + 'getRolePermissions';


  //Master Data Types
  // public masterDataDisplayColumns: MasterDataDisplayColumns = {
  //   bedTypes: ['bed_type_id', 'bed_type', 'is_active', 'actions'],
  //   propertyTypes: ['property_type_id', 'property_type', 'is_active', 'actions'],
  //   washRoomTypes: ['washroom_type_id', 'washroom_type', 'is_active', 'actions'],
  //   countries: ['country_id', 'country_name', 'country_code_alpha_2', 'country_code_alpha_3', 'country_isd_code', 'currency_name', 'currency_code', 'is_active', 'actions'],
  //   states: ['state_id', 'country_id', 'state_code', 'state_name', 'is_union_territory', 'is_active', 'actions'],

  // }


  // public masterData = {
  //   bodyTypes: {
  //     displayColumns: ['body_type_id', 'body_type', 'is_active']
  //   },

  //   castes:{
  //     displayColumns: ['caste_id']
  //   },

  //   cities: 'cities',
  //   countries: 'countries',
  //   genders: 'genders',
  //   gothrams: 'gothrams',
  //   habits: 'habits',
  //   maritalStatuses: 'maritalStatuses',
  //   motherTongues: 'motherTongues'
  // };

  private masterDataType: 'bodyTypes'
    | 'castes'
    | 'cities'
    | 'countries'
    | 'genders'
    | 'gothrams'
    | 'habits'
    | 'maritalStatuses'
    | 'motherTongues' = 'bodyTypes'

  constructor(private readonly http: HttpClient,) {
    console.log(environment.apiUrl);
  }


  /* Login */

  loginUser(data: object): Observable<any> {

    return this.http.post<any>(this.loginServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  generateOtp(data: object): Observable<any> {

    return this.http.post<any>(this.loginOtpGenerateServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }

  getUserMobileNumbers(userId: string): Observable<any> {
    return this.http.get(this.getUserMobileNumbersUrl + '/' + userId);
  }

  verifyOtp(data: object, verfication_id: string): Observable<any> {

    return this.http.post<any>(this.loginVerifyOtpServiceUrl + '/' + verfication_id, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  /* Logout */

  logoutUser(): Observable<any> {

    return this.http.get(this.logoutServiceUrl);
  }

  logoutUserFromAllDevices(): Observable<any> {

    return this.http.get(this.logoutAllServiceUrl);
  }




  getAllRoles(): Observable<any> {
    return this.http.get(this.listAllRolesUrl);
  }

  getUsersWithRole(): Observable<any> {
    return this.http.get(this.listAllUsersWithRolesUrl);
  }

  getUsersWithoutRole(): Observable<any> {
    return this.http.get(this.listAllUsersWithoutRolesUrl);
  }

  getAllUsersByRoleName(params: any): Observable<any> {
    return this.http.post(this.listUsersByRoleNameUrl, params);
  }

  createNewRole(params: any): Observable<any> {
    return this.http.post(this.createRoleUrl, params);
  }

  getRoleById(id: number): Observable<any> {
    return this.http.get(this.getRoleByIdUrl + '/' + id);
  }

  getRolesForUser(id: number): Observable<any> {
    return this.http.get(this.getRolesForUserUrl + '/' + id);
  }



  updateRole(data: any, role_id: number): Observable<any> {
    return this.http.put<any>(this.updateRoleUrl + '/' + role_id, data);
  }

  deleteRole(role_id: number): Observable<any> {
    return this.http.delete<any>(this.deleteRoleUrl + '/' + role_id);
  }

  assignRolesForUser(params: any, userId: number): Observable<any> {
    return this.http.post(this.assignRolesForUserUrl + '/' + userId, params);
  }

  getAllPermissionForRole(id: number): Observable<any> {
    return this.http.get(this.getAllPermissionsForRoleUrl + '/' + id);
  }




  /*

  Permissions

  */


  getAllPermissions(): Observable<any> {
    return this.http.get(this.listAllPermissionsUrl);
  }
  getUsersWithPermission(): Observable<any> {
    return this.http.get(this.listAllUsersWithPermissionsUrl);
  }

  getUsersWithoutPermission(): Observable<any> {
    return this.http.get(this.listAllUsersWithoutPermissionsUrl);
  }

  getAllUsersByPermissionName(params: any): Observable<any> {
    return this.http.post(this.listUsersByPermissionNameUrl, params);
  }

  createNewPermission(params: any): Observable<any> {
    return this.http.post(this.createPermissionUrl, params);
  }

  getPermissionById(id: number): Observable<any> {
    return this.http.get(this.getPermissionByIdUrl + '/' + id);
  }

  updatePermission(data: any, permission_id: number): Observable<any> {
    return this.http.put<any>(this.updatePermissionUrl + '/' + permission_id, data);
  }

  assignPermissionsForRole(params: any, userId: number): Observable<any> {
    return this.http.post(this.assignPermissionsForRoleUrl + '/' + userId, params);
  }


  deletePermission(permission_id: number): Observable<any> {
    return this.http.delete<any>(this.deletePermissionUrl + '/' + permission_id);
  }



  // Master Data
  getMasterDataList(dataType: MasterDataType): Observable<any> {
    let masterDataListUrl = environment.apiUrl + dataType;
    return this.http.get(masterDataListUrl);

  }

  getMDById(dataType: MasterDataType, id: number): Observable<any> {
    let getMasterDataUrl = environment.apiUrl + dataType;
    return this.http.get(getMasterDataUrl + '/' + id);
  }

  createMasterData(dataType: MasterDataType, params: any): Observable<any> {
    let createMasterDataUrl = environment.apiUrl + dataType;
    return this.http.post(createMasterDataUrl, params);
  }

  updateMasterData(dataType: MasterDataType, id: number, data: any): Observable<any> {
    let updateMasterDataUrl = environment.apiUrl + dataType;
    return this.http.put<any>(updateMasterDataUrl + '/' + id, data);
  }

  deleteMasterData(dataType: MasterDataType, id: number): Observable<any> {
    let deleteMasterDataUrl = environment.apiUrl + dataType;
    return this.http.delete<any>(deleteMasterDataUrl + '/' + id);
  }

  // getAllUsersAddedByUserId(user_id: string | null,  params: any): Observable<any> {
  //   // If user id passed, then other users or back end users are accessing the photos else the owner
  //   const getAllUsersAddedByUserIdUrl = (user_id !== null) ? this.getAllUsersAddedByUserIdUrl + '/' + user_id : this.getAllUsersAddedByUserIdUrl;
  //   return this.http.post(getAllUsersAddedByUserIdUrl, params);
  // }

  // getUserCreationCountPerDay(user_id: string | null): Observable<any> {
  //   // If user id passed, then other users or back end users are accessing the photos else the owner
  //   const getUserCreationCountPerDayUrl = (user_id !== null) ? this.getUserCreationCountPerDayUrl + '/' + user_id : this.getUserCreationCountPerDayUrl;
  //   return this.http.get(getUserCreationCountPerDayUrl);
  // }


}
